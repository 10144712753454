<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="companies"
        sort-by="calories"
        class="elevation-1"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Empresas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <!-- <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="success" dark class="mb-2" v-on="on">Adicionar</v-btn>
              </template>
              <v-card :loading="dialogLoading">
                <v-card-title>
                  <span class="text-h5">Adicionar Administradora</span>
                </v-card-title>

                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 sm12>
                        <v-text-field v-model="editedItem.name" label="Nome"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12>
                        <v-text-field v-model="editedItem.reportName" label="Nome Relatório">
                        </v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12>
                        <v-text-field v-model="editedItem.document" label="Documento">
                        </v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/services/api';

export default {
  data() {
    return {
      dialog: false,
      dialogLoading: false,
      companies: [],
      defaultItem: {
        name: '',
        reportName: '',
        document: '',
      },
      editedItem: {
        name: '',
        reportName: '',
        document: '',
      },
      loading: false,
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Nome Curto', value: 'shortName' },
        { text: 'Ticker', value: 'ticker' },
        { text: 'CNPJ', value: 'cnpj' },
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        const { data: { companies } } = await api.companies.list();
        this.companies = companies;
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
